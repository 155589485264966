/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CounterState {
  filterCount: any;
  counterList: any[];
  statusList: any[];
  linkTypeList: any[];
  cityList: any[];
  districtList: any[];
  stateList: any[];
  locationFormState: any;
  selectedCounterData: any;
  selectedCounterAttachments: any;
  addDivisionModalState: any;
  deactivateDivisionModalState: any;
  blockDivisionModalState: any;
  blockCompanyModalState: any;
  stockistsOfCounter: any;
  clusterList: any;
  productsOfCounter: any;
  delegatesData: any;
  stockistData: any;
  counterCompanyList: any;
  DeactivateCompanyModalState: any;
  EditProductState: any;
  IsCounterProductEdit: any;
  isUniqueError: any;
  valueCapCurrDivision: any;
  counterEdit: {
    type: boolean;
    formData: any;
  };
  companyEdit: {
    type: boolean;
    formData: any;
  };
  selectedCompanyData: any;
  primary_key: string | number | null | undefined;
  filters: {
    division: any[] | undefined;
    company: any[] | undefined;
    city: any[] | undefined;
    state: any[] | undefined;
    district: any[] | undefined;
    link: any[] | undefined;
    countercode: any[] | undefined;
    status: any[] | undefined;
  };
  companyList: any[];
  clickType: "row" | "create";
  bulkUploadModalState: boolean;
  isCreqImport: boolean;
  creqCode: string;
  isResetFilter: boolean;
}

const counterSlice = createSlice({
  name: "counter",
  initialState: {
    filterCount: null,
    selectedCounterData: {},
    selectedCounterAttachments: [],
    counterList: [],
    locationFormState: {},
    addDivisionModalState: {},
    deactivateDivisionModalState: {},
    DeactivateCompanyModalState: {},
    blockDivisionModalState: {},
    blockCompanyModalState: {},
    counterCompanyList: [],
    stockistsOfCounter: [],
    clusterList: [],
    companyList: [],
    statusList: [],
    linkTypeList: [],
    productsOfCounter: null,
    EditProductState: [],
    cityList: [],
    districtList: [],
    stateList: [],
    delegatesData: null,
    stockistData: null,
    clickType: "row",
    isUniqueError: false,
    IsCounterProductEdit: false,
    valueCapCurrDivision: {},
    counterEdit: {
      type: false,
      formData: {},
    },
    companyEdit: {
      type: false,
      formData: {},
    },
    selectedCompanyData: null,
    primary_key: null,
    filters: {
      division: undefined,
      company: undefined,
      city: undefined,
      state: undefined,
      district: undefined,
      link: undefined,
      countercode: undefined,
      status: undefined,
    },
    bulkUploadModalState: false,
    isCreqImport: false,
    creqCode: "",
    isResetFilter: false,
  } as CounterState,
  reducers: {
    setFilterCount(state, action: PayloadAction<any>) {
      state.filterCount = action.payload || {};
    },
    setCompanyList(state, action: PayloadAction<any[]>) {
      state.companyList = action.payload || [];
    },
    setAddDivisionModalState(state, action: PayloadAction<any>) {
      state.addDivisionModalState = action.payload || {};
    },
    setDeactivateDivisionModalState(state, action: PayloadAction<any>) {
      state.deactivateDivisionModalState = action.payload || {};
    },
    setLocationFormState(state, action: PayloadAction<any>) {
      state.locationFormState = action.payload || {};
    },
    setCounterList(state, action: PayloadAction<any[]>) {
      state.counterList = action.payload;
    },
    setSelectedCounterData(state, action: PayloadAction<any>) {
      state.selectedCounterData = action.payload;
    },
    setSelectedCounterAttachments(state, action: PayloadAction<any>) {
      state.selectedCounterAttachments = action.payload;
    },
    setLinkTypeList(state, action: PayloadAction<any[]>) {
      state.linkTypeList = action.payload;
    },
    setCityList(state, action: PayloadAction<any[]>) {
      state.cityList = action.payload;
    },
    setStatusList(state, action: PayloadAction<any[]>) {
      state.statusList = action.payload;
    },
    setDistrictList(state, action: PayloadAction<any[]>) {
      state.districtList = action.payload;
    },
    setStateList(state, action: PayloadAction<any[]>) {
      state.stateList = action.payload;
    },
    setDivisionFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.division = action.payload;
    },
    setCompanyFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.company = action.payload;
    },
    setCityFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.city = action.payload;
    },
    setStateFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.state = action.payload;
    },
    setDistrictFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.district = action.payload;
    },

    setCounterCodeFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.countercode = action.payload;
    },

    setLinkFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.link = action.payload;
    },

    setStatusFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.status = action.payload;
    },

    setBlockDivisionModalState(state, action: PayloadAction<any>) {
      state.blockDivisionModalState = action.payload || {};
    },
    setDelegates(state, action: PayloadAction<any>) {
      state.delegatesData = action.payload || {};
    },
    setStockist(state, action: PayloadAction<any>) {
      state.stockistData = action.payload || {};
    },
    setBlockCompanyModalState(state, action: PayloadAction<any>) {
      state.blockCompanyModalState = action.payload || {};
    },
    setDeactivateCompanyModalState(state, action: PayloadAction<any>) {
      state.DeactivateCompanyModalState = action.payload || {};
    },
    setClusterList(state, action: PayloadAction<any>) {
      state.clusterList = action.payload || {};
    },
    setStockistsOfCounter(state, action: PayloadAction<any>) {
      state.stockistsOfCounter = action.payload || {};
    },
    setProductsOfCounter(state, action: PayloadAction<any>) {
      state.productsOfCounter = action.payload || {};
    },
    setCounterCompanyList(state, action: PayloadAction<any>) {
      state.counterCompanyList = action.payload || {};
    },
    setEditProductState(state, action: PayloadAction<any>) {
      state.EditProductState = action.payload || {};
    },
    setIsCounterProductEdit(state, action: PayloadAction<any>) {
      state.IsCounterProductEdit = action.payload || false;
    },
    setCPFormData(state, action: PayloadAction<any[] | any | null | undefined>) {
      const { keyofForm, value } = action.payload;
      state.EditProductState[keyofForm] = value;
    },
    setUniqueError(state, action: PayloadAction<any>) {
      state.isUniqueError = action.payload || {};
    },

    setCounterEdit(state, action: PayloadAction<any>) {
      state.counterEdit = action.payload || {};
    },
    setCompanyEdit(state, action: PayloadAction<any>) {
      state.companyEdit = action.payload || {};
    },
    setPrimaryKey(state, action: PayloadAction<string | number | null | undefined>) {
      state.primary_key = action.payload;
    },
    setSelectedCompanyData(state, action: PayloadAction<any>) {
      state.selectedCompanyData = action.payload;
    },
    setClickType(state, action: PayloadAction<"row" | "create">) {
      state.clickType = action.payload || "create";
    },
    setBulkUploadModalState(state, action: PayloadAction<boolean>) {
      state.bulkUploadModalState = action.payload;
    },

    setValueCapCurrDivision(state, action: PayloadAction<any>) {
      state.valueCapCurrDivision = action.payload;
    },
    setIsCreqImport(state, action: PayloadAction<boolean>) {
      state.isCreqImport = action.payload;
    },
    setCreqCode(state, action: PayloadAction<string>) {
      state.creqCode = action.payload;
    },

    setIsResetFilter(state, action: PayloadAction<boolean>) {
      state.isResetFilter = action.payload;
    },
  },
});

export const counterActions = counterSlice.actions;
export { counterSlice };
