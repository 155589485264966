import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@radix-ui/react-tooltip";
import moment from "moment";
// import { useEffect, useState } from "react";
import { flattenObject } from "src/constants/functions";
import { switchString } from "src/utils/utils";

interface IDetailsGrid {
  dataObj: any;
  keyString?: (input: string) => string | undefined;
  className?: string;
  removeNestedData?: boolean;
}

const verifyStringType = function (input: string) {
  const numberRegex = /^\d+$/;

  if (!numberRegex.test(input)) {
    const dateMoment = moment(input, moment.ISO_8601, true); // Use ISO 8601 format for strict parsing

    if (dateMoment.isValid()) {
      console.log("input", input, dateMoment.format("DD MMM, YYYY"));
      return dateMoment.format("DD MMM, YYYY"); // Corrected format to display the year correctly
    }
  }

  return input;
};

const DetailsGrid = ({ dataObj, keyString, className, removeNestedData }: IDetailsGrid) => {
  const keyChangeFn = keyString || switchString;

  console.log("dataObj", dataObj);

  // const [_ ,setData] = useState<any>(dataObj || {});

  const processedDataObj =
    removeNestedData && dataObj
      ? Object.keys(dataObj).reduce((acc: any, key: any) => {
          if (typeof dataObj[key] !== "object") {
            acc[key] = dataObj[key];
          }
          return acc; // This line is added to return the accumulator
        }, {})
      : dataObj;
  // This should be outside the reduce function

  console.log("processedDataObj", processedDataObj);
  // useEffect(() => {
  //   if(processedDataObj && removeNestedData)
  //    setData(processedDataObj);
  // }, []);

  return (
    <div className={className ? className : "grid w-10/12 grid-cols-1 mt-5 xl:grid-cols-2 gap-x-20 gap-y-5"}>
      {processedDataObj &&
        Object.keys(processedDataObj).length > 0 &&
        Object.entries(flattenObject(processedDataObj)).map(
          ([key, value]) =>
            keyChangeFn(key) && (
              <div key={key?.toString()} className="grid grid-cols-2 text-base">
                <p className="text-[#49484C] place-self-center mr-auto whitespace-break-spaces text-base leading-[0.02rem] font-normal not-italic">
                  {keyChangeFn(key)}
                </p>
                <div className="mr-auto">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className="cursor-text" asChild>
                        <div className="flex">
                          <p className="text-[#1B1A1F] text-base font-semibold text-left mr-auto">
                            {typeof value === "string" || typeof value === "number"
                              ? verifyStringType(value?.toString())?.length > 20
                                ? verifyStringType(value?.toString()).slice(0, 20) + "..."
                                : verifyStringType(value?.toString())
                              : ""}
                          </p>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="text-[#1B1A1F] mr-auto">{typeof value === "string" ? verifyStringType(value) : ""}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            )
        )}
    </div>
  );
};

export default DetailsGrid;
