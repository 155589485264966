import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CompanyState {
  companyDetails: any[] | null | undefined;
  addEditFormData: any | null;
  isEdit: boolean;
  showAddEditForm: boolean;
  companyMasterTable: any[] | null | undefined;
}

const companySlice = createSlice({
  name: "company",
  initialState: {
    companyDetails: null,
    addEditFormData: null,
    isEdit: false,
    showAddEditForm: false,
    companyMasterTable: null,
  } as CompanyState,
  reducers: {
    setCompany(state, action: PayloadAction<any[] | null | undefined>) {
      state.companyDetails = action.payload;
    },
    setAddEditFormData(state, action: PayloadAction<any | null>) {
      state.addEditFormData = action.payload;
    },
    setIsEdit(state, action: PayloadAction<boolean>) {
      state.isEdit = action.payload;
    },
    setShowAddEditForm(state, action: PayloadAction<boolean>) {
      state.showAddEditForm = action.payload;
    },
    setCompanyMasterTable(state, action: PayloadAction<any[] | null | undefined>) {
      state.companyMasterTable = action.payload;
    },
  },
});

export const companyActions = companySlice.actions;
export { companySlice };
