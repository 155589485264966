import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { ApiBlocker, api, changeApiBlocker, changeShowErrorToast, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

let instance: AxiosInstance;
let eventInstance: AxiosInstance;

let pendingTimer: any;
const handlePendingState = (config: any, resolve: any, reject: any, attempt = 0) => {
  const params = new URLSearchParams(window.location.search);
  if (ApiBlocker.isEventPending) {
    if (attempt >= 10) {
      reject(new Error("Maximum retry attempts reached."));
    }
    clearTimeout(pendingTimer); // Clear the previous timer
    pendingTimer = setTimeout(() => {
      const stateId = params.get("id");
      if (stateId) ApiBlocker.eventChecked(`state_${stateId}`);
      handlePendingState(config, resolve, reject, attempt + 1); // Recursively call the function until the event is not pending
    }, 1000); // Wait for 1 second before checking again
  } else {
    resolve(addAuthHeader(config)); // Proceed with the API call if not pending
  }
};

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/state",
  });

  // Add the interceptor to the instance
  instance.interceptors.response.use(
    (success: any) => {
      const stateId = window.sessionStorage.getItem("stateId");
      if (stateId) ApiBlocker.eventChecked(`state_${stateId}`);
      return success;
    },
    (error) => {
      if (error?.response?.status === 403) {
        // logout
        logout();
      }
    }
  );

  instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
};
const axiosEventInstance = async () => {
  eventInstance = axios.create({
    baseURL: api + "/event/state",
  });

  // Add the interceptor to the instance
  instance.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }
  });

  eventInstance.interceptors.request.use(
    (config) => {
      return new Promise((resolve, reject): any => {
        if (
          ApiBlocker.isEventBlocked &&
          config.url !== "/district/add" &&
          config.url !== "/city/add" &&
          config.url !== "/state/add" &&
          config.url !== "/city/replace"
        ) {
          const errorMessage = "Request cancelled due to event blocked status";
          changeShowErrorToast(true, errorMessage);
          changeApiBlocker(false, true);
          reject(errorMessage);
        } else {
          handlePendingState(config, resolve, reject, 1);
        }
      });
    },
    (error) => {
      if (error?.response?.status === 403) {
        logout();
      }
      return Promise.reject(error);
    }
  );
};

axiosInstance();
axiosEventInstance();

export const getStateDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get("/details" + query);
};
export const getCityDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get("/city" + query);
};
export const getDistrictDetails = async (query: string): Promise<AxiosResponse> => {
  return instance.get("/district" + query);
};
export const addGeography = async (region: string, data: any): Promise<AxiosResponse> => {
  return eventInstance.post(`/${region.toLowerCase()}/add`, data, {
    validateStatus: function (status) {
      return status <= 500 && status >= 200;
    },
  });
};
export const addState = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.post("/add", data, {
    validateStatus: function (status) {
      return status <= 500 && status >= 200;
    },
  });
};

export const editDistrict = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.patch("/district/edit", data, {
    validateStatus: function (status) {
      return status <= 500 && status >= 200;
    },
  });
};

export const editCity = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.patch("/city/edit", data, {
    validateStatus: function (status) {
      return status <= 500 && status >= 200;
    },
  });
};

export const changeDistrictActiveStatus = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.patch("/district/edit", data, {
    validateStatus: function (status) {
      return status <= 500 && status >= 200;
    },
  });
};

export const changeCityActiveStatus = async (data: any): Promise<AxiosResponse> => {
  return eventInstance.patch("/city/edit", data, {
    validateStatus: function (status) {
      return status <= 500 && status >= 200;
    },
  });
};

export const postReplaceCity = async (data: any, primary_key: string | number): Promise<AxiosResponse> => {
  return eventInstance.post(
    "/city/replace",
    { data, event_type: "CITY_RELACED_ADD", primary_key: primary_key },
    {
      validateStatus: function (status) {
        return status <= 500 && status >= 200;
      },
    }
  );
};
