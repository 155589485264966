import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StockistState {
  clusterCompanyFormData: string | any;
  cityList: any;
  addEditFormData: any;
  linkedCompaniesData: any[];
  stockistFormData: any;
  selectedStockistData: any;
  showStockistMainForm: boolean;
  mainFormEditMode: boolean;
  stockistLinkEditMode: boolean;
  selectedDetailsTabIndex: number;
  independentCompanyAdd: boolean;
  clusterFormSectionCounter: number;
  stockistCompanystatus: boolean;
  filters: {
    company: any[] | undefined;
    city: any[] | undefined;
  };
}

const stockistSlice = createSlice({
  name: "stockist",
  initialState: {
    clusterCompanyFormData: {},
    loggedInUser: null,
    addEditFormData: {},
    linkedCompaniesData: [],
    cityList: null,
    stockistFormData: {},
    selectedStockistData: {},
    showStockistMainForm: false,
    mainFormEditMode: false,
    selectedDetailsTabIndex: 0,
    independentCompanyAdd: false,
    clusterFormSectionCounter: 1,
    stockistCompanystatus: false,
    stockistLinkEditMode: false,
    filters: {
      company: undefined,
      city: undefined,
    },
  } as StockistState,
  reducers: {
    linkClusterCompanyFormData(state, action: PayloadAction<any>) {
      state.clusterCompanyFormData = action.payload;
    },
    setCityList(state, action: PayloadAction<any>) {
      state.cityList = action.payload;
    },
    setFormData(state, action: PayloadAction<any[] | any | null | undefined>) {
      const { keyofForm, value } = action.payload;
      state.addEditFormData[keyofForm] = value;
    },
    setStockistLinkFormFullObject(state, action: PayloadAction<any>) {
      state.addEditFormData = action.payload;
    },
    setLinkedCompaniesData(state, action: PayloadAction<any>) {
      state.linkedCompaniesData.push(action.payload);
    },
    setStockistFormData(state, action: PayloadAction<any>) {
      const { keyofForm, value } = action.payload;
      state.stockistFormData[keyofForm] = value;
    },
    setSelectedStockistData(state, action: PayloadAction<any>) {
      state.selectedStockistData = action.payload;
    },
    setShowStockistMainForm(state, action: PayloadAction<boolean>) {
      state.showStockistMainForm = action.payload;
    },
    setStockistCompanystatus(state, action: PayloadAction<boolean>) {
      state.stockistCompanystatus = action.payload;
    },

    setStockistFullFormData(state, action: PayloadAction<any>) {
      state.stockistFormData = action.payload;
    },
    setMainFormEditMode(state, action: PayloadAction<boolean>) {
      state.mainFormEditMode = action.payload;
    },
    setSelectedDetailsTabIndex(state, action: PayloadAction<number>) {
      state.selectedDetailsTabIndex = action.payload;
    },
    setIndependentAddCompany(state, action: PayloadAction<boolean>) {
      state.independentCompanyAdd = action.payload;
    },
    setClusterFormSectionCounter(state, action: PayloadAction<number>) {
      state.clusterFormSectionCounter = action.payload;
    },
    setCompanyFilter(state, action: PayloadAction<any[]>) {
      state.filters.company = action.payload;
    },
    setCityFilter(state, action: PayloadAction<any[]>) {
      state.filters.city = action.payload;
    },

    setStockistLinkEditMode(state, action: PayloadAction<boolean>) {
      state.stockistLinkEditMode = action.payload;
    },

    resetLinkedCompanies(state) {
      state.linkedCompaniesData = [];
    },
  },
});

export const stockistActions = stockistSlice.actions;
export { stockistSlice };
