/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useCallback, useEffect, useState } from "react";
import SmartForm from "src/container/smart-form.component";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { addStockistCompany, editStockistCompany, queryStockist } from "src/services/stockist-api";
import { emailStatusHandler, mobileStatusHandler, stockistAliasCodeHandler, stockistCodeHandler } from "src/utils/utils";
import * as yup from "yup";
import StockistCluster from "./stockistCluster-form.component";

interface StockistLinkFormProps {
  onAddFormSubmit?: (formData: any) => void;
  onCancelClick?: () => void;
  onSuccess?: () => void;
}

const StockistLinkForm: React.FC<StockistLinkFormProps> = ({ onCancelClick, onSuccess }) => {
  // Define the schema object for the form validation
  // const [schemaObj, setSchemaObj] = useState<any>({});
  const [companydata, setcompanydata] = useState<any>({});
  const companyList = useSelector((state: RootState) => state.company.companyDetails);

  const { addEditFormData, selectedStockistData, stockistLinkEditMode, mainFormEditMode, independentCompanyAdd, clusterFormSectionCounter } =
    useSelector((state: RootState) => state.stockist);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Define the schema object for the form validation
  const [schemaObj, setSchemaObj] = useState<any>({
    company: yup.object().required(), //
    operator_name: yup.string().notRequired(), //
    operator_mobile: yup
      .number()
      .positive()
      .notRequired()
      .transform((value) => {
        return isNaN(value) ? undefined : value;
      })
      .test("len", "Mobile number should 10 characters", (val: any) => {
        if (!val || String(val).length === 10) return true;
        else return false;
      }),
    operator_email: yup
      .string()
      .required()
      .email("Invalid email format")
      .test("managerEmailId", "Invalid email Id", (val, context) => {
        if (typeof val === "string") emailStatusHandler(val, context);
        return true;
      }),
    plantCode: yup.string().required(), //
    compStockistCode: yup
      .string()
      .required()
      .notOneOf([yup.ref("compStockistAliasCode"), null], "Stockist Alias code & Stockist Code should be different"), //
    compStockistAliasCode: yup.string().required(), //
    stockist_type: yup.object().required("Stockist form is required"), //
    elnv_exempt: yup.object().required("E-inv exempt is required"), //
    remarks: yup.string().required(), //
  });

  // Loop through the number of sections and add the required fields to the schema object
  useEffect(() => {
    const updatedSchemaObj: any = {
      company: yup.object().required(), //
      operator_name: yup.string().notRequired(), //
      operator_mobile: yup
        .number()
        .positive()
        .notRequired()
        .nullable()
        .transform((value) => {
          return isNaN(value) ? undefined : value;
        })

        .test("len", "Mobile number should 10 characters", (val: any) => {
          if (!val || String(val).length === 10) return true;
          else return false;
        })
        .test("mobile-validation", "Invalid mobile number", (val, context) => {
          if (!val) return true;
          return mobileStatusHandler(val, context);
        }),

      operator_email: yup
        .string()
        .required()
        .email()
        .test("managerEmailId", "Invalid email Id", (val, context) => {
          if (typeof val === "string") emailStatusHandler(val, context);
          return true;
        }),
      plantCode: yup.string().required(), //
      compStockistCode: yup
        .string()
        .required()
        .test("stockist-validation", "Invalid Stockist code", (val, context) => {
          if (!val) return true;
          return stockistCodeHandler(val, context, addEditFormData?.id ? `id=${addEditFormData?.id}` : "");
        })
        .notOneOf([yup.ref("compStockistAliasCode"), null], "Stockist Alias code & Stockist Code should be different"), //
      compStockistAliasCode: yup
        .string()
        .required()
        .test("stockist-validation", "Invalid stockist alias code", (val, context) => {
          if (!val) return true;
          return stockistAliasCodeHandler(val, context, addEditFormData?.id ? `id=${addEditFormData?.id}` : "");
        }), //, //
      stockist_type: yup.object().required("Stockist form is required"), //
      elnv_exempt: yup.object().required("E-inv exempt is required"), //
      remarks: yup.string().required(), //
    };

    for (let i = 0; i < clusterFormSectionCounter; i++) {
      updatedSchemaObj[`distributionChannel__${i}`] = yup.string().notRequired();
      updatedSchemaObj[`paymentTermHdr__${i}`] = yup.object().required();
      updatedSchemaObj[`clusterCode__${i}`] = yup.object().required();
    }

    setSchemaObj(updatedSchemaObj);
  }, [clusterFormSectionCounter, addEditFormData]);

  // Define the schema for the form validation
  const schema = yup.object().shape({ ...schemaObj });

  // Define the schema for the form validation
  // const schema = yup.object().shape({ ...schemaObj });
  // let mainFormEditMode = true;

  const elnvExemptList = [
    { id: 1, elnv_exempt: "Yes" },
    { id: 2, elnv_exempt: "No" },
    // { id: 3, elnv_exempt: "exempt 3" },
    // Add more exemptions as needed
  ];

  const stockistTypeList = [
    { id: 2, stockist_type: "Regular" },
    { id: 1, stockist_type: "Skilled" },
    { id: 3, stockist_type: "Special" },
  ];

  const StockistLinkForm = [
    {
      label: "Select Company",
      type: "autocomplete",
      id: 2,
      keyofForm: "company",
      suggestionList: companyList?.filter((item) => {
        const isCodePresent = selectedStockistData?.stockist_company_lnk?.some((link: any) => link?.company?.code === item?.code);
        return !isCodePresent;
      }),
      display_key: "name",
      unique_key: "code",
    },
    {
      label: "Plant Code",
      type: "input",
      id: 1,
      keyofForm: "plantCode",
    },
    {
      label: "Stockist Alias Code",
      type: "input",
      id: 3,
      keyofForm: "compStockistAliasCode",
      // disabled: mainFormEditMode ? true : false,
    },
    {
      label: "Stockist Code",
      type: "input",
      id: 4,
      keyofForm: "compStockistCode",
      disabled: stockistLinkEditMode ? true : false,
    },
    {
      label: "Operator Name",
      type: "input",
      id: 5,
      keyofForm: "operator_name",
    },
    {
      label: "Operator Mobile number",
      type: "input",
      id: 6,
      keyofForm: "operator_mobile",
    },
    {
      label: "Remarks",
      type: "input",
      id: 11,
      keyofForm: "remarks",
    },
    {
      label: "Operator Email address",
      type: "e_mail",
      id: 7,
      keyofForm: "operator_email",
    },
    {
      label: "Stockist Type",
      type: "autocomplete",
      suggestionList: stockistTypeList,
      id: 8,
      keyofForm: "stockist_type",
      display_key: "stockist_type",
      unique_key: "id",
    },
    {
      label: "E-lnv. Exempt (Company)",
      type: "autocomplete",
      suggestionList: elnvExemptList,
      id: 10,
      keyofForm: "elnv_exempt",
      display_key: "elnv_exempt",
      unique_key: "id",
    },
  ];

  const fetchDetails = useCallback(async () => {
    if (!window.sessionStorage.getItem("stockistMasterId")) {
      navigate("/masters/stockists");
    }

    const queryParams = `?stockist_id=${window.sessionStorage.getItem("stockistMasterId")}`;

    let response: any = null;
    response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true`);
    if (response?.status !== 200) throw new Error();
    response = await queryStockist(`/${queryParams.toString()}&company=true&cluster=true`);

    // setIsLoading(false);
    dispatch(stockistActions.setSelectedStockistData(response?.data?.data[0]));
  }, [dispatch]);

  const handleLinkCompany = async () => {
    // console.log("formedData", formedData);

    const sortedKeys = Object.keys(addEditFormData).sort((a, b) => {
      const indexA = parseInt(a.split("__")[1]);
      const indexB = parseInt(b.split("__")[1]);
      return indexA - indexB;
    });

    const keysToCheck = ["clusterCode", "paymentTermHdr", "distributionChannel"];

    const linkedClusterArray: any[] = [];

    sortedKeys.forEach((key) => {
      if (keysToCheck.includes(key.split("__")[0])) {
        const index = parseInt(key.split("__")[1]);

        if (!linkedClusterArray[index]) {
          linkedClusterArray[index] = {};
        }

        if (key.split("__")[0] === "clusterCode") {
          linkedClusterArray[index]["clusterCode"] = addEditFormData[key]?.cluster_code;
          linkedClusterArray[index]["cluster_name"] = addEditFormData[key]?.cluster_name;
        } else if (key.split("__")[0] === "paymentTermHdr") {
          linkedClusterArray[index]["paymentTermHdr"] = addEditFormData[key]?.paymentTermCode; //to be checked
        } else if (key.split("__")[0] === "margin") {
          linkedClusterArray[index]["margin"] = addEditFormData[key]?.margin; //to be checked
        } else if (key.split("__")[0] === "distributionChannel") {
          linkedClusterArray[index]["distributionChannel"] = addEditFormData[key];
        }
      }
    });

    const data: any = {
      company: addEditFormData?.company?.code, // Assuming company is represented by an ID
      company_name: addEditFormData?.company?.name,
      operator_name: addEditFormData?.operator_name,
      operator_mobile: addEditFormData?.operator_mobile,
      operator_email: addEditFormData?.operator_email,
      plantCode: addEditFormData?.plantCode,
      remarks: addEditFormData?.remarks,
      compStockistCode: addEditFormData?.compStockistCode,
      compStockistAliasCode: addEditFormData?.compStockistAliasCode,
      stockist_type: addEditFormData?.stockist_type?.stockist_type,
      stockist: selectedStockistData?.stockist_id,
      elnv_exempt: addEditFormData?.elnv_exempt?.elnv_exempt,
      active_status: "Y", //to be checked
      stockistClusterLnk: linkedClusterArray,
    };
    if (mainFormEditMode) {
      data.id = addEditFormData?.id;
    }

    try {
      let resCompany;
      if (mainFormEditMode)
        resCompany = await editStockistCompany(
          {
            ...data,
            stockist: selectedStockistData?.stockist_id,
            medvol_code: selectedStockistData?.medvol_code,
            owner_name: selectedStockistData?.owner_name,
            firm_name: selectedStockistData?.firm_name, //to be checked
            city: selectedStockistData?.city?.city_code,
            email: selectedStockistData?.email,
          },
          selectedStockistData?.stockist_id
        );
      else
        resCompany = independentCompanyAdd
          ? await addStockistCompany(
              {
                ...data,
                stockist: selectedStockistData?.stockist_id,
                medvol_code: selectedStockistData?.medvol_code,
                active_status: "Y",
                owner_name: selectedStockistData?.owner_name,
                firm_name: selectedStockistData?.firm_name,
                city: selectedStockistData?.city?.city_code,
                email: selectedStockistData?.email,
              },
              selectedStockistData?.stockist_id
            )
          : null;

      if (resCompany?.status === 200) {
        dispatch(stockistActions.setShowStockistMainForm(false));
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(stockistActions.setLinkedCompaniesData(data));
    setTimeout(() => {
      fetchDetails();
    }, 1500);

    if (onSuccess) onSuccess();
  };

  const autocompleteHandler = (value: any, keyofForm: string) => {
    if (keyofForm === "company") {
      setcompanydata(value);
    }

    dispatch(
      stockistActions.setFormData({
        value: value,
        keyofForm: keyofForm,
      })
    );
  };

  console.log("addEditFormData", addEditFormData);

  return (
    <SmartForm isControlled={true} defaultValues={structuredClone(addEditFormData)} schema={schema} onFormSubmit={handleLinkCompany}>
      <div className="p-6 md:w-[51rem] relative w-full grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4">
        {StockistLinkForm.map(({ label, type, id, placeholder, keyofForm, suggestionList, display_key, unique_key, disabled }: any) => {
          switch (type.toString().toLocaleLowerCase().trim()) {
            case "autocomplete":
              return (
                <Fragment key={id}>
                  <div className="w-[363px]">
                    <SmartForm.Autocomplete
                      fieldName={keyofForm}
                      label={label}
                      suggestionList={suggestionList || []}
                      displayKey={display_key}
                      disabled={disabled ? true : false}
                      isControlled={true}
                      value={
                        addEditFormData?.[keyofForm]
                          ? typeof addEditFormData?.[keyofForm] === "object"
                            ? addEditFormData?.[keyofForm]?.[display_key]
                            : addEditFormData?.[keyofForm]
                          : ""
                      }
                      uniqueKey={unique_key}
                      onChange={(value) => {
                        autocompleteHandler(value, keyofForm);
                      }}
                      placeholder={placeholder || ""}
                      key={id}
                      className="w-[363px]"
                    />
                  </div>
                </Fragment>
              );
            default:
              return (
                <SmartForm.Input
                  type={type}
                  placeholder={placeholder || ""}
                  label={label}
                  disabled={disabled ? true : false}
                  key={id}
                  fieldName={keyofForm}
                  className="w-[363px]  mr-auto"
                  inputClassName="w-[363px]"
                  blurHandler={(value, keyofForm) => {
                    dispatch(
                      stockistActions.setFormData({
                        value: value,
                        keyofForm: keyofForm,
                      })
                    );
                  }}
                />
              );
          }
        })}
        <div className="col-span-1 md:col-span-2">
          <StockistCluster companyData={companydata} />
        </div>

        <div className="flex justify-end w-full col-span-1 gap-2 pt-4 md:col-span-2">
          <Button className="gap-2 border" onClick={onCancelClick}>
            Cancel
          </Button>
          <Button type="submit" className="bg-[#7F56D9] text-white">
            {mainFormEditMode ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </SmartForm>
  );
};

export default StockistLinkForm;
