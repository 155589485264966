import { FC, ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Navbar } from "src/components";
import { FloatingProgressMenu } from "src/components/ui/floating-progress.component";
import { Toaster } from "src/components/ui/toaster.component";
import { bannerStatic, toastStatic } from "src/constants/global-const";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";

interface LayoutProps {
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const minimized = useSelector((state: RootState) => state.floatingMenu.menuVisible);
  const menuList = useSelector((state: RootState) => state.floatingMenu.menuList);

  const { toast } = useToast();

  useEffect(() => {
    if ((bannerStatic.isEventBlocked || bannerStatic.isEventPending) && toastStatic.value) {
      toast({ description: toastStatic.message, variant: "destructive" });
    }
  }, [toastStatic, bannerStatic]);

  return (
    <>
      <Navbar />
      {minimized && <FloatingProgressMenu list={menuList} />}
      {children ? children : <Outlet />}
      <Toaster />
    </>
  );
};

export default Layout;
