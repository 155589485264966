import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, SubHeader } from "src/components";
import { companyActions } from "src/context/company-slice";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { getAllCompanyDetails } from "src/services/company-api";
import { getAllCityDetails } from "src/services/product-api";
import StockistLinkForm from "./forms/stockistLink-form.component";
import StockistForm from "./forms/stockist-form";

const StockistLayout = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const clusterCompanyFormData = useSelector((state: RootState) => state.stockist.clusterCompanyFormData);
  const { showStockistMainForm, selectedStockistData, mainFormEditMode } = useSelector((state: RootState) => state.stockist);

  useEffect(() => {
    getAllCityDetails().then((res) => {
      dispatch(stockistActions.setCityList(res?.data?.data));
    });
  }, []);

  useEffect(() => {
    getAllCompanyDetails()
      .then((res) => dispatch(companyActions.setCompany(res.data)))
      .catch((err) => console.log(err));
  }, []);

  const handleLinkCompany = () => {
    dispatch(stockistActions.linkClusterCompanyFormData({ ...clusterCompanyFormData, status: false }));
  };

  return (
    <div className="w-full">
      <SubHeader
        onAddNew={() => {
          dispatch(stockistActions.setShowStockistMainForm(true));
          dispatch(stockistActions.setStockistLinkFormFullObject({}));
          dispatch(stockistActions.linkClusterCompanyFormData({}));
          dispatch(stockistActions.setMainFormEditMode(false));
          dispatch(stockistActions.setStockistFullFormData({}));
          dispatch(stockistActions.setIndependentAddCompany(false));
          dispatch(stockistActions.resetLinkedCompanies());
        }}
      />
      {children}
      {!clusterCompanyFormData?.status && showStockistMainForm && (
        <Modal
          isOpen={showStockistMainForm}
          onCloseModal={() => {
            dispatch(stockistActions.setStockistFullFormData(selectedStockistData));
            dispatch(stockistActions.setShowStockistMainForm(false));
            dispatch(stockistActions.resetLinkedCompanies());
          }}
          isHeader={true}
          title={!mainFormEditMode ? "Create a Stockist" : "Edit a Stockist"}
        >
          <StockistForm
            onAddFormSubmit={() => {
              dispatch(stockistActions.setShowStockistMainForm(false));

              dispatch(stockistActions.linkClusterCompanyFormData({ ...clusterCompanyFormData, status: false }));
            }}
            onCancelClick={() => {
              dispatch(stockistActions.setShowStockistMainForm(false));
              dispatch(stockistActions.resetLinkedCompanies());

              dispatch(stockistActions.linkClusterCompanyFormData({ ...clusterCompanyFormData, status: false }));
            }}
          />
        </Modal>
      )}
      {clusterCompanyFormData?.status && (
        <Modal
          isOpen={clusterCompanyFormData?.status}
          onCloseModal={() => {
            dispatch(stockistActions.setStockistLinkEditMode(false));
            dispatch(stockistActions.linkClusterCompanyFormData({ ...clusterCompanyFormData, status: false }));
          }}
          onBack={() => {
            dispatch(stockistActions.setStockistLinkEditMode(false));
            dispatch(stockistActions.linkClusterCompanyFormData({ ...clusterCompanyFormData, status: false }));
          }}
          isHeader={true}
          title="Link a company"
        >
          <StockistLinkForm
            onCancelClick={() => {
              dispatch(stockistActions.setStockistLinkEditMode(false));

              dispatch(stockistActions.linkClusterCompanyFormData({ ...clusterCompanyFormData, status: false }));
            }}
            onSuccess={() => handleLinkCompany()}
          />
        </Modal>
      )}
    </div>
  );
};

export default StockistLayout;
